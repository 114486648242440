<template>
  <div class="dashboard-layout background mr-4 mr-md-0">
    <the-header class="mr-n4 mr-md-0" />

    <v-main>
      <v-container class="pa-0 mt-md-8">
        <v-row>
          <!-- Sidebar -->
          <v-col class="hidden-md-and-down" cols="12" lg="3">
            <Sidebar />
          </v-col>

          <!-- Main -->
          <v-col class="mr-n4 mr-md-0" cols="12" lg="9">
            <router-view />
          </v-col>
        </v-row>
      </v-container>
    </v-main>

    <the-footer class="mr-n4 mr-md-0" />
  </div>
</template>

<script>
import { mapState } from "vuex";

import TheHeader from "@/components/shared/headers/TheHeader";
import Sidebar from "@/components/dashboard/pages/Sidebar";
import TheFooter from "@/components/shared/footers/TheFooter";

export default {
  name: "LimeLoansDashboard",
  components: { TheHeader, Sidebar, TheFooter },

  data() {
    return {};
  },

  computed: {
    ...mapState({ auth: "auth" }),
  },

  created() {
    if (
      this.auth &&
      this.auth.user &&
      this.auth.user.banking_details &&
      this.auth.user.banking_details.bank_name &&
      this.auth.user.banking_details.account_number &&
      this.auth.user.banking_details.bank_name == "Standard Bank" &&
      this.auth.user.banking_details.account_number == "123456789"
    ) {
      //
    } else if (!this.auth.user.has_banking || !this.auth.user.has_next_of_kin) {
      this.$router.push({ name: "RegistrationPage" });
    }
  },

  mounted() {},

  methods: {},
};
</script>

<style lang="scss" scoped>
.dashboard-layout {
  min-height: 100%;
}
</style>
