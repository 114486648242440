<template>
  <div class="sidebar">
    <div></div>
    <v-card
      class="transparent d-flex flex-column align-center"
      max-width="266"
      flat
    >
      <v-card class="transparent" width="270" flat tile>
        <div class="list">
          <v-list class="pa-0" width="100%" rounded flat>
            <!-- My loan -->
            <v-list-item
              :to="{ name: 'DashboardMyLoanPage' }"
              class="gray800--text"
              exact-active-class="bg-active"
            >
              <v-icon>$walletIcon</v-icon>

              <h6 class="h6 ml-1">My loan</h6>
            </v-list-item>

            <v-img
              alt="divider"
              class="divider mt-n2"
              src="@/assets/img/dashboard/pages/sidebar/divider.svg"
            />

            <!-- Personal data -->
            <v-list-item
              :to="{ name: 'DashboardPersonalDataPage' }"
              class="gray800--text"
              exact-active-class="bg-active"
            >
              <v-icon>$userLIcon</v-icon>
              <h6 class="h6 ml-1">Personal data</h6>
            </v-list-item>
          </v-list>
        </div>
      </v-card>

      <!-- Need help -->
      <!-- <div class="need-help-btn mt-3 pointer">
        <h6 class="b-btn-text primary--text">Need help</h6>
      </div> -->
    </v-card>
  </div>
</template>

<script>
export default {
  name: "LimeLoansSidebar",

  data() {
    return {
      dialog: false,
    };
  },

  mounted() {},

  methods: {},
};
</script>

<style lang="scss" scoped>
.h6 {
  font-family: "Raleway";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
}

.need-help-btn {
  border: 1px solid #5d9922;
  border-radius: 4px;
  /* Button */

  box-sizing: border-box;

  /* Auto layout */

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0px 32px;
  gap: 8px;

  width: 264px;
  height: 48px;
}

.sidebar {
  .list {
    box-shadow: 0px 0px 8px rgba(61, 61, 61, 0.12),
      0px 2px 4px rgba(61, 61, 61, 0.06);
    border-radius: 8px;
  }
}

.bg-active {
  background-color: white;
  color: #5d9922 !important;
  // box-shadow: 0px 0px 8px rgba(61, 61, 61, 0.12),
  //   0px 2px 4px rgba(61, 61, 61, 0.06);
  border-radius: 8px;
}
</style>
